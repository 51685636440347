import store from '../store'
import global from './global.js'

function saveMsg(obj) {
  let msg = JSON.parse(localStorage.getItem('msg'))
  if (!msg) {
    msg = []
  }
  let flag = false
  msg.forEach(item => {
    if (item.uid === global.uid) {
      // 加时间
      if (obj.time - item.data[item.data.length - 1].time > 120) {
        item.data.push({
          type: 4,
          time: obj.time,
          msgtype: 'text'
        })
      }
      item.data.push(obj)
      flag = true
    }
  })
  if (!flag) {
    let arr = []
    arr.push({
      type: 4,
      time: obj.time,
      msgtype: 'text'
    })
    arr.push(obj)
    msg.push({
      uid: global.uid,
      data: arr
    })
  }

  localStorage.setItem('msg', JSON.stringify(msg))
  msg.forEach(item => {
    if (item.uid === global.uid) {
      store.commit("handleMsg", item.data)
    }
  })
}
export default {
  saveMsg
}

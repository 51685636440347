import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
  path: "/",
  redirect: '/Chatroom'
}, {
  path: "/Chatroom",
  name: "Chatroom",
  component: () => import("../views/chatRoom/index.vue"),
  meta: {
    title: "客服"
  }
}];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    window.document.title = to.meta.title
  }
  // if (to.meta.requireAuth) {
  //     let token = Cookies.get('access_token');
  //     let anonymous = Cookies.get('user_name');
  //     if (token) {

  //             next({
  //                 path: '/login',
  //                 query: {
  //                     redirect: to.fullPath
  //                 }
  //             })

  //     }
  // }
  next();
})
export default router;

import axios from 'axios'
import global from './global.js'
const baseUrl = 'https://testdigitalize.cnweisou.net'; // 正式线域名
// const baseUrl = 'https://digitization.5g-center.com'; // 测试线域名
let qs = require('qs')

function post(url = '', data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(baseUrl + url, qs.stringify(data), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'access-token': global.token
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function uploadFile(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + url, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'access-token': global.token
        }
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default {
  uploadFile,
  post
}
